import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, Card } from "react-bootstrap";
import { fetchData } from "../../../utils/fetchData";
import axios from "axios";

const TripDetails = () => {
  const { id } = useParams();
  const [tripDetails, setTripDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [historyTabError, setHistoryTabError] = useState(null); // Track error specific to the history tab

  useEffect(() => {
    const fetchTripDetails = async () => {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData)?.token;

      try {
        const dataObj = { request: `trips/trip-details/${id}` };
        const response = await fetchData(dataObj, "POST", token);
        if (response?.data?.tripDetails) {
          setTripDetails(response.data.tripDetails[0]);
        } else {
          setError("לא נמצאו פרטי הנסיעה.");
        }
      } catch (err) {
        setError("שגיאה בטעינת פרטי הנסיעה.");
        console.error("Fetch error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTripDetails();
  }, [id]);

  const fetchHistoryData = async (lineCode) => {
    try {
      const url = `https://interfaceserviceapi.y-it.co.il/FcApiService/FcApiService.svc/InvokeFcApiService?authenticationToken=d8707759-45d2-492a-01a2-c430d1f6c82c&$action=get&$objects=DIARYCHANGELOG&$lineCode=${lineCode}`;
      const response = await axios.get(url);
      if (response?.data?.data?.diaryChangeLogObject) {
        setHistoryData(response.data.data.diaryChangeLogObject[0]);
        setHistoryTabError(null); // Clear any previous error
      } else {
        setHistoryTabError("שגיאה: לא נמצאו היסטוריית שינויים.");
      }
    } catch (err) {
      setHistoryTabError("שגיאה: לא נמצאו היסטוריית שינויים.");
      console.error("History fetch error:", err);
    } finally {
      setLoadingHistory(false);
    }
  };

  const handleTabSelect = (key) => {
    if (key === "history" && tripDetails?.lineCode) {
      fetchHistoryData(tripDetails.lineCode); // Fetch history when history tab is selected
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  if (loading) return <div>טוען...</div>;
  if (error) return <div>שגיאה: {error}</div>;

  const [startDate, startTime] = tripDetails?.orderStartTime?.split(" ") || [
    "",
    "",
  ];
  const [endDate, endTime] = tripDetails?.orderEndTime?.split(" ") || ["", ""];

  const totalPassengers = tripDetails?.stations?.reduce(
    (total, station) => total + station.passengers.length,
    0
  );

  const formatAddress = (street, house, city) => {
    return [street, house, city].filter(Boolean).join(", ");
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    return phone.replace(/(\d{3})(\d+)/, "$1-$2");
  };

  return (
    <div className="container mb-4">
      <h1 className="font-weight-bold">נסיעה מספר {tripDetails?.lineCode}</h1>
      <div className="card shadow-sm">
        <Tabs
          defaultActiveKey="details"
          id="trip-details-tabs"
          className="mb-3 d-flex"
          onSelect={handleTabSelect} // Handle tab selection
        >
          <Tab eventKey="details" title="פרטי הנסיעה" className="">
            <div className="card-body">
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">נסיעה מספר:</strong>{" "}
                {tripDetails?.lineCode ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">תיאור הקו:</strong>{" "}
                {tripDetails?.lineDescription ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">סטטוס:</strong>{" "}
                {tripDetails?.isActive === "1" ? "פעיל" : "לא פעיל"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">לקוח:</strong>{" "}
                {tripDetails?.clientName ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">זמן התחלה:</strong>{" "}
                {startTime || "N/A"} ({startDate && formatDate(startDate)})
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">זמן סיום:</strong>{" "}
                {endTime || "N/A"} ({endDate && formatDate(endDate)})
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">סוג הנסיעה:</strong>{" "}
                {tripDetails?.lineType ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">סניף מארח:</strong>{" "}
                {tripDetails?.hostBranch ?? "N/A"} (קוד:{" "}
                {tripDetails?.hostBranchCode || "N/A"})
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">סוג רכב:</strong>{" "}
                {tripDetails?.orderCarType ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">קבלן מבצע:</strong>{" "}
                {tripDetails?.driverNickName ?? "N/A"} (סוג:{" "}
                {tripDetails?.driverTypeName || "N/A"})
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">טלפון נהג 1:</strong>{" "}
                {tripDetails?.driverPhone1 || "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">טלפון נהג 2:</strong>{" "}
                {tripDetails?.driverPhone2 || "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">טלפון נייד נהג:</strong>{" "}
                {tripDetails?.driverMobilePhone || "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">ליווי א:</strong>{" "}
                {tripDetails?.escortANickName ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">
                  טלפון נייד ליווי א:
                </strong>{" "}
                {tripDetails?.escortAMobilePhone ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">ליווי ב:</strong>{" "}
                {tripDetails?.escortBNickName ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">
                  טלפון נייד ליווי ב:
                </strong>{" "}
                {tripDetails?.escortBMobilePhone ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">מחיר ללקוח:</strong>{" "}
                {tripDetails?.clientPrice ?? "N/A"} ₪
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">מחיר לנהג:</strong>{" "}
                {tripDetails?.driverPrice ?? "N/A"} ₪
              </p>
              {tripDetails?.shortRemarks && (
                <p className="card-text">
                  <strong className="font-weight-bold">הערות קצרות:</strong>{" "}
                  {tripDetails.shortRemarks}
                </p>
              )}
              {tripDetails?.longRemarks && (
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: tripDetails.longRemarks,
                  }}
                ></p>
              )}
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">מספר ויזה:</strong>{" "}
                {tripDetails?.visaNumber ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">קוד קבוצה:</strong>{" "}
                {tripDetails?.groupName ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">קוד ערכה:</strong>{" "}
                {tripDetails?.setCode ?? "N/A"}
              </p>
              <p className="card-text m-0 p-0">
                <strong className="font-weight-bold">פעולה אחרונה:</strong>{" "}
                {tripDetails?.lastAction ?? "N/A"}
              </p>
            </div>
          </Tab>

          {/* Tab 2: Stations and Passengers */}
          <Tab eventKey="stations" title="תחנות ונוסעים" className="">
            <div className="card-body">
              {tripDetails?.stations?.map((station, index) => (
                <div key={index}>
                  <p className="m-0 p-0 lead font-weight-bold">
                    {station.stationTime}
                  </p>

                  <Card className="mb-3 shadow-sm">
                    <Card.Body>
                      <Card.Title>
                        <strong className="font-weight-bold">תחנה:</strong>{" "}
                        {formatAddress(
                          station.stationStreet,
                          station.stationHouse,
                          station.stationCity
                        )}
                      </Card.Title>
                      {/* Other station details */}
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </Tab>

          {/* Tab 3: History */}
          <Tab eventKey="history" title="היסטוריה" className="">
            <div className="card-body">
              {loadingHistory && <div>טוען היסטוריה...</div>}
              {!loadingHistory && historyData ? (
                <>
                  <p>
                    <strong>תאריך:</strong>{" "}
                    {formatDate(historyData.relativeDate)}
                  </p>
                  <p>
                    <strong>סטטוס:</strong>{" "}
                    {historyData.isActive === "1" ? "פעיל" : "לא פעיל"}
                  </p>
                  <h5>שינויים</h5>
                  {historyData.changes.map((change, index) => (
                    <Card key={index} className="mb-3 shadow-sm">
                      <Card.Body>
                        <p>
                          <strong>שינוי על ידי:</strong> {change.changedByName}
                        </p>
                        <p>
                          <strong>זמן שינוי:</strong>{" "}
                          {formatDate(change.changeTime.split(" ")[0])}
                        </p>
                        <p>
                          <strong>אובייקט שינוי:</strong> {change.changeObject}
                        </p>
                        <p>
                          <strong>ערך ישן:</strong> {change.oldValue}
                        </p>
                        <p>
                          <strong>ערך חדש:</strong> {change.newValue}
                        </p>
                      </Card.Body>
                    </Card>
                  ))}
                </>
              ) : (
                !loadingHistory &&
                historyTabError && <div>{historyTabError}</div>
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default TripDetails;
